import React from 'react';
// import Cookies from 'js-cookie';
import { Container, Row, Col } from "react-bootstrap";
import Topbar from '../components/topbar';
import { getRandomString, encryptStringWithSHA256, hashToBase64url } from "../util/index"
import Cookies from 'js-cookie';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loginLink: `` };
    }

    async componentDidMount() {
        const state = getRandomString();
        // console.log(`state value is ${state}`);
        //localStorage.setItem("state", state);
        Cookies.set('state', state)
        // Create PKCE code verifier
        const code_verifier = getRandomString();
        // console.log(`code_verifier value is ${code_verifier}`);
        //localStorage.setItem("code_verifier", code_verifier);
        Cookies.set('code_verifier', code_verifier)
        // Create code challenge
        const arrayHash = await encryptStringWithSHA256(code_verifier);
        const code_challenge = hashToBase64url(arrayHash);
        // console.log(`code_challenge value is ${code_challenge}`);
        //localStorage.setItem("code_challenge", code_challenge)
        Cookies.set('code_challenge', code_challenge)
        // Redirtect user-agent to /authorize endpoint
        const LOGIN_URL = process.env.REACT_APP_DOMAIN_NAME + "/oauth2/authorize?response_type=code&state=" + state + "&client_id=" + process.env.REACT_APP_APP_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_REDIRECT_URI + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge;
        // console.log(`LOGIN_URL value is ${LOGIN_URL}`)
        window.location.href = LOGIN_URL;
        this.setState({
            loginLink :LOGIN_URL,
            code_challenge,
            state,
            code_verifier
        })
    }

    render() {
        return ''
        // return <Container fluid>
        //     <Row className="justify-content-md-center">
        //         <Topbar />
        //         <Col xs={12} style={{ textAlign: "center" }}>
        //             {
        //                 <h1>Login Page</h1>
        //             }
        //         </Col>

        //         <Col xs={3} >
        //             {/* <Link to={{pathname: this.state.LOGIN_URL}}>
        //             <Button variant="secondary" size="lg" block>
        //             Click Here to Login
        //         </Button>
        //             </Link>
        //          */}


        //             <a className="btn btn-secondary btn-block btn-lg text-white" href={this.state.loginLink}>Click Here to Login</a>
        //         </Col>

        //     </Row>
        // </Container>


    }
}

export default Login;
import '../asset/css/table.css';
import '../asset/css/style.css';
import React from 'react';
import { Table, Spin } from 'antd';
import jp from "jsonpath";
import SiteAPI from '../service/Sites';
import { getURL } from '../util/index';
import Constant from '../util/Constant.json';
const ERROE_FILTER_PATH = '@.error =="E" || @.warehouse=="EW" || @.warehouse=="EWCN" || @.warehouse=="EWAU" || @.ledger=="EL"'

class DaySites extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sites: [],
            isAPICallNeeded: false,
            loader: true
        };
    }


    getJSONPathFilterExpression(selectedFlags) {

        console.log(selectedFlags)
        let filterExpression = '$.x[?(';
        selectedFlags.forEach((flag, index) => {
            console.log(flag.flagName === 'error')
            filterExpression = filterExpression + (index === 0 ?
                flag.flagName === 'error' ? ERROE_FILTER_PATH : `@.${flag.flagName}` :
                flag.flagName === 'error' ? '|| ' + ERROE_FILTER_PATH : ` || @.${flag.flagName}`)
        });
        return filterExpression + ')]'
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isAPICallNeeded !== this.state.isAPICallNeeded) {
            this.setState({ loader: true, isAPICallNeeded: this.props.isAPICallNeeded, sites: [] }, async () => {
                this.setState({ sites: await this.getSiteAPIResponse(), loader: false })
            });;
        }
    }

    async getSiteAPIResponse() {
        const sidebarDataResponse = JSON.parse(localStorage.getItem('sideBarData')).message;
        const { country, selectedDate, selectedFlags, filterData } = this.props;
        let { siteName, status } = filterData
        let tempStatus = status.length ? (status.length > 1 ? "0,1" : (status[0] === "Active" ? "1" : "0")) : "0,1"
        let url = getURL(Constant.URL.GET_DAY_SITE_DATA);
        url.searchParams.append('countryIso', sidebarDataResponse[country].countryIso);
        url.searchParams.append('fromDate', selectedDate);
        url.searchParams.append('Status', tempStatus);
        const result = await SiteAPI.getDayData(url.href, {});
        let filterResponse = [];
        if (result.response?.regions) {
            let sites = result.response.regions[0].countries[0].sites;
            sites = sites.active.concat(sites.inactive);
            filterResponse = (siteName?.length) ?
              (selectedFlags?.length ? jp.query({ x: jp.query({ x: sites }, this.getSiteNameJSONFilterExpression(siteName)) }, this.getJSONPathFilterExpression(selectedFlags)) : jp.query({ x: sites }, this.getSiteNameJSONFilterExpression(siteName)))
                : (selectedFlags?.length) ? jp.query({ x: sites }, this.getJSONPathFilterExpression(selectedFlags)) : sites;
        }

        return filterResponse;
    }

    getSiteNameJSONFilterExpression = (selectedSites) => {

        let filterExpression = '$..[?(';
        selectedSites.forEach((site, index) => {
            filterExpression = filterExpression + (index === 0 ? `@.SiteName=="${site}"` : ` || @.SiteName=="${site}"`)
        });

        filterExpression = filterExpression + ')]';
        return filterExpression;
    }

    async componentDidMount() {
        this.setState({ sites: await this.getSiteAPIResponse(), isAPICallNeeded: this.props.isAPICallNeeded, loader: false });;
    }


    render() {

        if (this.state.loader) {
            return <div style={{ textAlign: "center" }}><Spin spin={true} size='large' /></div>
        } else {
            const stores = this.state;
            let columns = [
                { title: 'Stores', dataIndex: 'name', key: 'name', width: 300, render: text => <p style={{ float: "left" }}>{text}</p> },

            ];

            columns = [...columns, {
                title: 'Missing', dataIndex: 'missing', key: 'missing ', width: 100,

                render(text, record) {
                    return {
                        props: {
                            style: text ? { background: "Yellow", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <p>{text}</p>
                    };
                }
            },
            {
                title: 'Closed', dataIndex: 'closed', key: 'closed ',
                render(text, record) {
                    return {
                        props: {
                            style: text ? { background: "Gray", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: 'Error', dataIndex: 'error', key: 'error ',
                render(text, record) {
                    return {
                        props: {
                            style: text ? { background: "rgba(221,12,12,.815)", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: 'Warehouse', dataIndex: 'warehouse', key: 'warehouse ',
                render(text, record) {
                    return {
                        props: {
                            style: text && ["EW", "EWCN", "EWAU"].includes(text) ? { background: "orange", padding: "10px" } : text && text === "IP" ? { background: "#8aacfa", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: 'Ledger', dataIndex: 'ledger', key: 'ledger',
                render(text, record) {
                    return {
                        props: {
                            style: text && text === "EL" ? { background: "red", padding: "10px" } : text && text === "IP" ? { background: "#8aacfa", padding: "10px" } : { background: "", padding: "10px" }
                        },
                        children: <div>{text}</div>
                    };
                }
            }]

            const siteIds = stores.sites.map((site, index) => {

                return {
                    key: index,
                    name: site.SiteName,
                    missing: jp.query(site, '$..missing')[0],
                    closed: jp.query(site, '$..closed')[0],
                    processing: jp.query(site, '$..processing')[0],
                    error: jp.query(site, '$..error')[0],
                    warehouse: jp.query(site, '$..warehouse')[0],
                    ledger: jp.query(site, '$..ledger')[0],
                }
            })

            return <div className="storeTable">
                <Table
                    className="test"
                    size={"small"}
                    bordered="true"
                    columns={columns}
                    dataSource={siteIds}
                    pagination={false}
                    scroll={{
                        y: 240
                    }} />
            </div>
        }
    }
}


export default DaySites;
import { FcClearFilters } from "react-icons/fc";
import { RiFilterOffLine, RiFilterLine } from "react-icons/ri"
import React from "react";
import { Tooltip } from "antd";


const style = {
    fontSize: '20px',
    color: '#fff'
}

class FilterButton extends React.Component {

    render() {

        let { country, region, siteName, status } = this.props.filterData;
        return (

            this.props.isFilterApplied ?
                (<Tooltip title="Reset Filters">
                    <RiFilterOffLine onClick={e => this.props.onResetFilter(e)} style={style} />
                </Tooltip>)
                : (country?.length || region?.length || siteName?.length || status?.length) ?
                    <Tooltip title="Apply Filters">
                        <RiFilterLine onClick={e => this.props.onFilterApply(e)} style={style} />
                    </Tooltip>
                    :
                    <Tooltip title="Please select atleast one filter to see the results.">
                        <FcClearFilters style={{ fontSize: "20px", color: "white", cursor: "not-allowed" }} />
                    </Tooltip>
        )
    }
}


export default FilterButton;
import '../asset/css/style.css'
import '../asset/css/table.css';
import { Table, Tooltip, Spin } from "antd";
import { useState, useEffect, useRef } from 'react';
import React from "react";
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import moment from "moment";
import Constant from '../util/Constant.json';
import { getURL } from '../util/index';
import SiteService from '../service/Sites';

function VirtualTable(props) {
    const { columns, scroll } = props;
    const [tableWidth, setTableWidth] = useState(0);
    const widthColumnCount = columns.filter(({ width }) => !width).length;
    const mergedColumns = columns.map((column) => {
        if (column.width) {
            return column;
        }

        return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
    });
    const gridRef = useRef();
    const [connectObject] = useState(() => {
        const obj = {};
        Object.defineProperty(obj, 'scrollLeft', {
            get: () => null,
            set: (scrollLeft) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({
                        scrollLeft,
                    });
                }
            },
        });
        return obj;
    });

    const resetVirtualGrid = () => {
        if (gridRef.current) {
            gridRef.current.resetAfterIndices({
                columnIndex: 0,
                shouldForceUpdate: false,
            });
        }
    };

    useEffect(() => resetVirtualGrid, [tableWidth]);

    const getClassName = (dayFlag) => {

        if (dayFlag.startsWith("C")) return "closedTile"

        switch (dayFlag) {
            case "M":
                return "missingTile";
            case "E":
                return "fileProcessingTile";
            case "EL":
                return "ledgerErrorTile";
            case "EW":
                return "warehouseErrorTile";
            case "Y":
                return "processingTile";
            case "ELW":
                return "lederWareHouseErrorTile"
            case "IP":
                return "inProgressTile"
            default:
                return '';
        }

    }

    const renderClassName = (text) => {
        let className = "";
        let completeText = ""
        if (text) {
            let splittedText = text.split("|");  //check for multiple pipe seperator
            for (let splittedTextIndex = 0; splittedTextIndex < splittedText.length; splittedTextIndex++) {

                if (splittedText[splittedTextIndex]) {
                    let delimiter = splittedText[splittedTextIndex].lastIndexOf("#");  //get the # part 

                    let substringFrom = 0; //from were we need to split the string
                    if (delimiter > 2) {
                        substringFrom = 2; //if delimiter is more than 2 then we need show the second the code 
                    } else if (delimiter === -1) {
                        delimiter = splittedText[splittedTextIndex].length //only when no # is present
                    }

                    if (completeText) substringFrom++;  //if EL & EW both are present

                    completeText += splittedText[splittedTextIndex].substring(substringFrom, delimiter); //create completeText
                }
            }
            className = getClassName(completeText); //get the className
        }

        return { className, completeText };
    }

    const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
        ref.current = connectObject;
        const totalHeight = rawData.length * 35;
        return (
            <Grid
                ref={gridRef}
                className="virtual-grid"
                columnCount={mergedColumns.length}
                columnWidth={(index) => {
                    const { width } = mergedColumns[index];
                    return totalHeight > scroll.y && index === mergedColumns.length - 1
                        ? width - scrollbarSize - 1
                        : width;
                }}
                height={scroll.y}
                rowCount={rawData.length}
                rowHeight={() => 35}
                width={tableWidth}
                onScroll={({ scrollLeft }) => {
                    onScroll({
                        scrollLeft,
                    });
                }}
            >
                {({ columnIndex, rowIndex, style }) => {
                    let text = rawData[rowIndex][mergedColumns[columnIndex].dataIndex];
                    let { className, completeText } = renderClassName(text);
                    if (completeText === 'EW' && text.split('-').length === 2) {
                        completeText = text.split('-')[1].includes('CNAPAC') ? 'EWAU' : 'EWCN'
                        className = 'warehouseErrorTileCN';
                    }
                    return (
                        <div
                            className={classNames('virtual-table-cell', {
                                [className]: className
                            })}
                            style={style}
                        >
                            <Tooltip title={text}>
                                <span >{completeText}</span>
                            </Tooltip>
                        </div>
                    )
                }}
            </Grid>
        );
    };

    return (
        <ResizeObserver
            onResize={({ width }) => {
                setTableWidth(width);
            }}
            className="storeTable"
        >
            <Table
                {...props}
                columns={mergedColumns}
                pagination={false}
                className="test"
                tableLayout="fixed"
                bordered="true"
                components={{
                    body: renderVirtualList,
                }}
            />
        </ResizeObserver>
    );
} // Usage

class Sites extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            siteData: {
                sites: []
            },
            loader: <Spin spin={true} size='large' />
        };
        localStorage.setItem(`${this.props.country}monthSiteLastRequestedURL`, null);
    }


    async componentDidMount() {
        try {
            let result = await this.getData();
            if (result)
                this.setState({ siteData: { sites: result || [] }, loader: '' });
        } catch (error) {
        }

    }

    async componentDidUpdate() {
        try {
            let result = await this.getData();
            console.log(`result`, result);
            if (result)
                this.setState({ siteData: { sites: result || [] }, loader: '' });
        }
        catch (e) {
        }
    }

    getData = async () => {
        let url = new getURL(Constant.URL.GET_MONTHLY_DATA);
        url.searchParams.append('date', this.props.selectedDate);
        url.searchParams.append('region', this.props.region);
        url.searchParams.append('country', this.props.country);
        let statusValue = this.getStatusValue(this.props.filterData.status);
        if (statusValue !== null) url.searchParams.append('status', statusValue);
        if (localStorage.getItem(`${this.props.country}monthSiteLastRequestedURL`) !== url.href) {
            console.log(`last url`, localStorage.getItem(`${this.props.country}monthSiteLastRequestedURL`));
            localStorage.setItem(`${this.props.country}monthSiteLastRequestedURL`, url.href);
            let availableData = await SiteService.getMonthData(url.href, {});
            return availableData;
        }
        else {
            throw new Error('Same data stopped');
        }
    }

    getStatusValue(input) {
        if (input.length === 1) {
            return input[0].toLocaleUpperCase() === 'ACTIVE' ? true : false;
        }
        else {
            return null;
        }
    }

    render() {
        const stores = this.state.siteData;
        const noOfDays = moment(this.props.selectedDate, "YYYY-MM").daysInMonth();
        const dayOfSelectedMonth = moment(this.props.selectedDate, "YYYY-MM").dayOfYear();
        let dynamicColumns = [];
        let columns = [
            {
                title: 'Stores',
                dataIndex: 'name',
                key: 'name',
                width: 200,
                // fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (text) => (
                    <Tooltip title={text}>
                        <span style={{ textAlign: "center" }}>{text}</span>
                    </Tooltip>
                )
            }
        ];

        //create columns first
        for (let columnIndex = 0; columnIndex < noOfDays; columnIndex++) {
            const singleObj = {
                title: <Tooltip title={dayOfSelectedMonth + columnIndex}>{`${columnIndex + 1}`}</Tooltip>, //month number
                dataIndex: columnIndex,
                key: columnIndex,
                [columnIndex]: columnIndex,
                width: noOfDays.length > 99 ? 45 : 34,  //this will assigned the width 
            }
            columns = [...columns, singleObj]
        }

        let values = []
        let siteIds = [];
        //Now create stores first with id and days

        if (this.state.loader) {
            return <div style={{ textAlign: "center" }}>{this.state.loader}</div>
        } else {
            if (stores) {

                values = { ...stores };

                if (values.sites && values.sites.length && this.props.filterData.siteName && this.props.filterData.siteName.length) {
                    values.sites = values.sites.filter(d => this.props.filterData.siteName.includes(d.siteName));
                }
                values.sites.forEach((site) => {
                    let singleObj = {
                        key: site.id,
                        name: site.siteName
                    }
                    site.days.forEach((day, i) => {

                        day = day ? day : ""
                        singleObj = { ...singleObj, ...{ [i]: day } }

                    });

                    siteIds = [...siteIds, singleObj]
                })
            }


            dynamicColumns = [...columns, ...dynamicColumns]

            if (siteIds.length) {
                return (
                    <VirtualTable
                        columns={dynamicColumns}
                        dataSource={siteIds}
                        scroll={{
                            y: 240,
                            x: '1200',
                        }}
                    />
                )
            }
            else
                return <div className="ant-empty ant-empty-normal"><div className="ant-empty-image"><svg className="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse className="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g className="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" className="ant-empty-img-simple-path"></path></g></g></svg></div><div className="ant-empty-description">No Data</div></div>;

        }

    }
}



export default Sites;
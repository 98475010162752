import APICall from './api-call';

class Sites {

    static async getMonthData(URL, requestOption) {
        try {
            let response = await APICall.request(URL, requestOption);
            return response;
        } catch (error) {
            console.log('Exception while fetching Monthly data for Sites', error);
            return {
                message: []
            }
        }

    }

    static async getDayData(URL, requestOption) {
        try {
            let response = await APICall.request(URL, requestOption);
            return response;
        } catch (error) {
            console.log('Exception while fetching Day data for Sites', error);
            return {
                message: {}
            }
        }

    }
}
export default Sites;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import Login from './views/login';
import Setup from './components/SetupCode';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

ReactDOM.render(
    <Router>
      <Switch>
        <Route path="/dashboard">
          <App />
        </Route>
        <Route path="/setup/:slug">
          <Setup />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

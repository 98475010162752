import '../asset/css/Sidebar.css';
import { Collapse, Tooltip, Spin } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import React from 'react';
import jsonPath from 'jsonpath';
import FilterButton from './FilterButton';
import SidebarService from '../service/Sidebar';
import Constant from '../util/Constant.json'
import InputComponents from './inputComponents';
import { getURL } from '../util/index';
import Config from '../util/Config.json';

const { Panel } = Collapse;
const SITE_TRUNCATE_LIMIT = 50;
const COUNTRY_TRUNCATE_LIMIT = 10;
const DEFAULT_STATUS_LIST = ['active', 'inactive']

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            region: '', country: '', siteName: '', isCollapsed: true,
            facets: Config.FACETS,
            regionList: [],
            countryList: [],
            siteNameList: [],
            isSitesTruncated: false,
            isCountriesTruncated: false,
            selectedRegion: [],
            selectedCountry: [],
            selectedSiteName: [],
            selectedStatus: ["Active"],
            sideBarResponse: [],
            status: true,
            isFilterApplied: true,
            loader: <Spin spin={true} size='small' />,
            activeKeyList: [],
            siteNameWithoutSerach: []
        };
        this.onLoad();
    }

    applyURLParams = () => {
        let url = new URL(window.location.href);
        let searchParams = url.searchParams;
        let region = searchParams.get('region');
        let countries = searchParams.get('countries');
        let siteNames = searchParams.get('sitename');
        let status = searchParams.get('status');
        let activeKeyList = searchParams.get('activeKeyList');
        activeKeyList = activeKeyList ? activeKeyList.split(',') : [];
        let regionsList = [];
        let countryList = [];
        let siteNameList = [];
        let statusList = [];
        if (region) {
            regionsList = region.split(',');
        }
        if (countries) {
            countryList = countries.split(',');
        }
        if (siteNames) {
            siteNameList = siteNames.split(',');
        }
        if (status != null) {
            statusList = status.split(',');
        } else {
            statusList = ['Active'];
        }


        let dataToBeUpdated = {};

        if (regionsList.length)
            dataToBeUpdated['region'] = regionsList;
        else
            dataToBeUpdated['region'] = [];

        if (countryList.length)
            dataToBeUpdated['country'] = countryList;
        else
            dataToBeUpdated['country'] = [];

        if (siteNameList.length)
            dataToBeUpdated['siteName'] = siteNameList;
        else
            dataToBeUpdated['siteName'] = [];

        if (statusList.length)
            dataToBeUpdated['status'] = statusList;
        else
            dataToBeUpdated['status'] = [];

        this.setState({ selectedCountry: dataToBeUpdated.country, selectedRegion: dataToBeUpdated.region, selectedSiteName: dataToBeUpdated.siteName, selectedStatus: dataToBeUpdated.status, activeKeyList: activeKeyList });
    }

    updateParent(key = null, value = null, isResetFilter = false) {
        let data = {
            region: this.state.selectedRegion,
            country: this.state.selectedCountry,
            siteName: this.state.selectedSiteName,
            status: this.state.selectedStatus,
            activeKeyList: this.state.activeKeyList
        };
        if (key != null && value != null)
            data[key] = value;
        this.props.onFilterChange(data, isResetFilter);
    }

    getActualSiteNameList() {
        let response = JSON.parse(localStorage.getItem('sideBarData'));
        let siteNameResponse = [];
        let statusList = this.state.selectedStatus.length ? this.state.selectedStatus : DEFAULT_STATUS_LIST;
        statusList.forEach(status => {
            status = status.toLowerCase().replace(' ', '');
            siteNameResponse.push(...jsonPath.query(response, `$.message.*.sites.${status}[*].SiteName`));
        });
        let isSitesTruncated = siteNameResponse?.length > SITE_TRUNCATE_LIMIT ? true : false;
        const siteToBeDisplayed = jsonPath.query(siteNameResponse, `$[:${SITE_TRUNCATE_LIMIT}]`);
        this.setState({ sideBarResponse: response.message, siteNameList: siteToBeDisplayed, siteNameResponse: siteNameResponse, isSitesTruncated, siteNameWithoutSerach: [] }, () => {
            let filterSelectedSiteName = [...this.state.selectedSiteName];
            this.state.selectedSiteName.forEach(s => {
                if (!this.state.siteNameList.includes(s)) {
                    filterSelectedSiteName.splice(filterSelectedSiteName.indexOf(s), 1);
                }
            });
            this.setState({ selectedSiteName: filterSelectedSiteName });
        });
    }

    getActualContry() {
        let countries = [];
        let response = JSON.parse(localStorage.getItem('sideBarData'));
        response.message.regions.map(d => {
            countries = [...countries, ...d.countries];
            return false;
        });
        let isCountriesTruncated = countries?.length > COUNTRY_TRUNCATE_LIMIT ? true : false;
        const countriesToBeDisplayed = jsonPath.query(countries, `$[:${COUNTRY_TRUNCATE_LIMIT}]`);
        this.setState({ countryList: countriesToBeDisplayed, countriesResponse: countries, isCountriesTruncated });
    }

    getActualRegion() {
        let response = JSON.parse(localStorage.getItem('sideBarData'));
        let regions = [];
        response.message.regions.map(d => {
            regions.push(d.name);
            return false;
        });
        this.setState({ regionList: regions });
    }

    onLoad = async () => {
        let url = getURL(Constant.URL.GET_REGION);
        let response = await SidebarService.getRegionData(url.href, {});
        let regions = [];
        let countries = [];
        response.message.regions.map(d => {
            regions.push(d.name);
            return false;
        });

        response.message.regions.map(d => {
            countries = [...countries, ...d.countries];
            return false;
        });
        localStorage.setItem('sideBarData', JSON.stringify(response));
        let siteNameResponse = [];
        let statusList = this.state.selectedStatus.length ? this.state.selectedStatus : DEFAULT_STATUS_LIST;
        statusList.forEach(status => {
            status = status.toLowerCase().replace(' ', '');
            siteNameResponse.push(...jsonPath.query(response, `$.message.*.sites.${status}[*].SiteName`));
        });
        let isSitesTruncated = siteNameResponse?.length > SITE_TRUNCATE_LIMIT ? true : false;
        const siteToBeDisplayed = jsonPath.query(siteNameResponse, `$[:${SITE_TRUNCATE_LIMIT}]`);
        let isCountriesTruncated = countries?.length > COUNTRY_TRUNCATE_LIMIT ? true : false;
        const countriesToBeDisplayed = jsonPath.query(countries, `$[:${COUNTRY_TRUNCATE_LIMIT}]`);
        this.setState({ regionList: regions, countryList: countriesToBeDisplayed, isCountriesTruncated, sideBarResponse: response.message, siteNameList: siteToBeDisplayed, siteNameResponse: siteNameResponse, countriesResponse: countries, loader: '', isSitesTruncated });

        this.applyURLParams();
    }

    updateSiteNameList = async (key, value) => {
        let params = this.state;
        params[key] = value;
        let countryListToBeFiltered = [];
        let response = JSON.parse(localStorage.getItem('sideBarData'));

        if (params.selectedRegion && params.selectedCountry && params.selectedRegion.length && params.selectedCountry.length) {
            countryListToBeFiltered = params.selectedCountry;
            let siteNameList = [];
            let statusList = this.state.selectedStatus.length ? this.state.selectedStatus : DEFAULT_STATUS_LIST;
            countryListToBeFiltered.forEach(d => {
                statusList.forEach(status => {
                    status = status.toLowerCase().replace(' ', '');
                    let data = jsonPath.query(response, `$.message["${d}"].sites.${status}[*].SiteName`);
                    siteNameList.push(...data);
                });
            });
            let isSitesTruncated = siteNameList?.length > SITE_TRUNCATE_LIMIT ? true : false;
            const siteToBeDisplayed = jsonPath.query(siteNameList, `$[:${SITE_TRUNCATE_LIMIT}]`);
            this.setState({ siteNameList: siteToBeDisplayed, siteNameResponse: siteNameList, isSitesTruncated, siteNameWithoutSerach: [] }, () => {
                let filterSelectedSiteName = [...this.state.selectedSiteName];
                this.state.selectedSiteName.forEach(s => {
                    if (!this.state.siteNameList.includes(s)) {
                        filterSelectedSiteName.splice(filterSelectedSiteName.indexOf(s), 1);
                    }
                });
                this.setState({ selectedSiteName: filterSelectedSiteName });
            });
        }
        else if (params.selectedRegion && params.selectedRegion.length) {
            let regionList = params.selectedRegion;
            for (let index = 0; index < regionList.length; index++) {
                const element = regionList[index];
                let jpData = jsonPath.query(response, `$.message.regions[?(@.name == "${element}")].countries`);
                countryListToBeFiltered.push(...jpData[0]);
            }

            let siteNameList = [];
            let statusList = this.state.selectedStatus.length ? this.state.selectedStatus : DEFAULT_STATUS_LIST;
            countryListToBeFiltered.forEach(d => {
                statusList.forEach(status => {
                    status = status.toLowerCase().replace(' ', '');
                    let data = jsonPath.query(response, `$.message["${d}"].sites.${status}[*].SiteName`);
                    siteNameList.push(...data);
                });
            });

            let isSitesTruncated = siteNameList?.length > SITE_TRUNCATE_LIMIT ? true : false;
            const siteToBeDisplayed = jsonPath.query(siteNameList, `$[:${SITE_TRUNCATE_LIMIT}]`);
            this.setState({ siteNameList: siteToBeDisplayed, siteNameResponse: siteNameList, isSitesTruncated, siteNameWithoutSerach: [] }, () => {
                let filterSelectedSiteName = [...this.state.selectedSiteName];
                this.state.selectedSiteName.forEach(s => {
                    if (!this.state.siteNameList.includes(s)) {
                        filterSelectedSiteName.splice(filterSelectedSiteName.indexOf(s), 1);
                    }
                });
                this.setState({ selectedSiteName: filterSelectedSiteName });
            });

        }
        else if (params.selectedCountry && params.selectedCountry.length) {
            countryListToBeFiltered = params.selectedCountry;
            let siteNameList = [];
            let statusList = this.state.selectedStatus.length ? this.state.selectedStatus : DEFAULT_STATUS_LIST;
            countryListToBeFiltered.forEach(d => {
                statusList.forEach(status => {
                    status = status.toLowerCase().replace(' ', '');
                    let data = jsonPath.query(response, `$.message["${d}"].sites.${status}[*].SiteName`);
                    siteNameList.push(...data);
                });
            });
            let isSitesTruncated = siteNameList?.length > SITE_TRUNCATE_LIMIT ? true : false;
            const siteToBeDisplayed = jsonPath.query(siteNameList, `$[:${SITE_TRUNCATE_LIMIT}]`);
            this.setState({ siteNameList: siteToBeDisplayed, siteNameResponse: siteNameList, isSitesTruncated, siteNameWithoutSerach: [] }, () => {
                let filterSelectedSiteName = [...this.state.selectedSiteName];
                this.state.selectedSiteName.forEach(s => {
                    if (!this.state.siteNameList.includes(s)) {
                        filterSelectedSiteName.splice(filterSelectedSiteName.indexOf(s), 1);
                    }
                });
                this.setState({ selectedSiteName: filterSelectedSiteName });
            });
        }
        else {
            this.getActualSiteNameList();
        }
    }

    updateFilter = (key, value) => {
        switch (key) {
            case 'region':
                this.updateCountryList(value);
                this.updateSiteNameList('selectedRegion', value);
                break;
            case 'country':
                this.updateSiteNameList('selectedCountry', value);
                break;
            case 'siteName':
                this.updateSiteNameList('selectedSiteName', value);
                break;
            case 'status':
                this.updateSiteNameList('status', value);
                break;
            default:
                break;
        }
    }

    updateCountryList(value) {
        let response = this.state.sideBarResponse;
        let countries = [];

        if (value.length) {
            response.regions.map(d => {
                if (value.includes(d.name)) {
                    countries = [...countries, ...d.countries];
                }
                return false;
            });
        }
        else {
            response.regions.map(d => {
                countries = [...countries, ...d.countries];
                return false;
            });
        }
        let isCountriesTruncated = countries?.length > COUNTRY_TRUNCATE_LIMIT ? true : false;
        const countriesToBeDisplayed = jsonPath.query(countries, `$[:${COUNTRY_TRUNCATE_LIMIT}]`);
        this.setState({ countryList: countriesToBeDisplayed, countriesResponse: countries, isCountriesTruncated, siteNameWithoutSerach: [] }, () => {

            let filterSelectedCountry = [...this.state.selectedCountry];
            this.state.selectedCountry.forEach(sc => {
                if (!this.state.countryList.includes(sc)) {
                    filterSelectedCountry.splice(filterSelectedCountry.indexOf(sc), 1);
                }
            });

            let filterSelectedSiteName = [...this.state.selectedSiteName];
            this.state.selectedSiteName.forEach(s => {
                if (!this.state.siteNameList.includes(s)) {
                    filterSelectedSiteName.splice(filterSelectedSiteName.indexOf(s), 1);
                }
            });
            this.setState({ selectedSiteName: filterSelectedSiteName, selectedCountry: filterSelectedCountry });
        });
    }

    changeRegion = (value) => {
        this.setState({ selectedRegion: value, isFilterApplied: false }, () => {
            this.updateFilter('region', value);
        });
    }

    changeCountry = (value) => {
        this.setState({ selectedCountry: value, isFilterApplied: false }, () => {
            this.updateFilter('country', value);
        });
    }

    changeSiteName = (value) => {
        this.setState({ selectedSiteName: value, isFilterApplied: false });
    }

    chnageSiteStaus = (value) => {
        this.setState({ selectedStatus: value, isFilterApplied: false }, () => {
            this.updateFilter('status', value);
        });
    }

    getCurrentPanel = (key) => {
        this.setState({ activeKeyList: key });
    }

    searchSiteName = (text) => {
        let actualList = this.state.siteNameResponse;
        if (text.length) {
            let filterArray = actualList.filter((d) => {
                return d.includes(text);
            });
            let isSitesTruncated = filterArray?.length > SITE_TRUNCATE_LIMIT ? true : false;
            const siteToBeDisplayed = jsonPath.query(filterArray, `$[:${SITE_TRUNCATE_LIMIT}]`);

            this.setState({ siteNameList: siteToBeDisplayed, isSitesTruncated, selectedSiteName: [...this.state.selectedSiteName, ...filterArray], isFilterApplied: false, siteNameWithoutSerach: this.state.selectedSiteName });
        }
        else {
            let isSitesTruncated = actualList?.length > SITE_TRUNCATE_LIMIT ? true : false;
            const siteToBeDisplayed = jsonPath.query(actualList, `$[:${SITE_TRUNCATE_LIMIT}]`);
            this.setState({ siteNameList: siteToBeDisplayed, isSitesTruncated, selectedSiteName: this.state.siteNameWithoutSerach });
        }
    }

    serachCountry = (text) => {
        let actualCountries = this.state.countriesResponse;
        if (text.length) {
            let filterCountries = actualCountries.filter((d) => {
                return d.includes(text);
            });
            let isCountriesTruncated = filterCountries?.length > COUNTRY_TRUNCATE_LIMIT ? true : false;
            this.setState({ countryList: filterCountries, isCountriesTruncated });
        } else {
            let isCountriesTruncated = actualCountries?.length > COUNTRY_TRUNCATE_LIMIT ? true : false;
            const countriesToBeDisplayed = jsonPath.query(actualCountries, `$[:${COUNTRY_TRUNCATE_LIMIT}]`);
            this.setState({ countryList: countriesToBeDisplayed, isCountriesTruncated });
        }
    }

    searchRegion(text) {

    }

    statusChange = (event) => {
        this.updateSiteNameList('status', event);
    }

    onFilterApply = async () => {
        // let selectedCountry = this.state.countryList.filter(d => this.state.selectedCountry.includes(d));
        // let selectedSiteName = this.state.siteNameList.filter(d => this.state.selectedSiteName.includes(d));

        this.setState({ isFilterApplied: true }, () => {
            this.updateParent();
        });
    }

    onResetFilter = () => {
        this.setState({ selectedCountry: [], selectedRegion: [], selectedSiteName: [], selectedStatus: ["Active"], isFilterApplied: false }, () => {
            this.updateParent(null, null, true);
            this.getActualContry();
            this.getActualSiteNameList();
        });

    }

    onExpandAndCollapse = () => {
        let grid = this.state.isCollapsed ? { sm: 12, md: 12, lg: 12, className: '' } : { sm: 12, md: 9, lg: 9, className: '' }
        this.setState({ isCollapsed: !this.state.isCollapsed }, () => this.props.onExpandAndCollapse(grid))
    }

    render() {
        let facets = this.state.facets;
        let filterData = {
            region: this.state.selectedRegion || [],
            country: this.state.selectedCountry || [],
            siteName: this.state.selectedSiteName || [],
            status: this.state.selectedStatus || [],
        };

        return (
            <div>
                <Collapse
                    activeKey={this.state.activeKeyList}
                    className={`sidebar__bg mt ${this.state.isCollapsed ? 'd-md-block ' : 'd-md-none'}`}
                    onChange={async key => await this.getCurrentPanel(key)}>

                    <div className="filter">
                        <div className="filter-title">Filters</div>
                        <FilterButton isFilterApplied={this.state.isFilterApplied} onFilterApply={this.onFilterApply} onResetFilter={this.onResetFilter} filterData={filterData} style={{ float: "right" }} />
                    </div>

                    <Panel
                        className="sidebar__bg"
                        header='Region'
                        key="1"
                    >
                        <div style={{ textAlign: "center" }}>{this.state.loader}</div>
                        <InputComponents.CheckBox className="facet-panel"
                            facets={facets}
                            facetsList={this.state.regionList}
                            onChange={this.changeRegion}
                            onSearch={this.searchRegion}
                            selectedList={this.state.selectedRegion}
                        />
                    </Panel>
                    <Panel
                        className="sidebar__bg"
                        header='Countries'
                        key="2"
                    >
                        <div style={{ textAlign: "center" }}>{this.state.loader}</div>
                        <InputComponents.CheckBox className="facet-panel"
                            facets={facets}
                            facetsList={this.state.countryList}
                            onChange={this.changeCountry}
                            onSearch={this.serachCountry}
                            isDataTruncated={this.state.isCountriesTruncated}
                            selectedList={this.state.selectedCountry}
                        />
                    </Panel>
                    <Panel
                        className="sidebar__bg"
                        header='Site Status'
                        key="3"
                    >
                        <div style={{ textAlign: "center" }}>{this.state.loader}</div>
                        <InputComponents.CheckBox className="facet-panel"
                            facets={facets}
                            facetsList={["Active", "In Active"]}
                            onChange={this.chnageSiteStaus}
                            // onSearch={this.searchSiteName}
                            // status={true}

                            selectedList={this.state.selectedStatus}
                        />
                    </Panel>
                    <Panel
                        className="sidebar__bg site_name"
                        header='Site Name'
                        key="4"
                    >
                        <div style={{ textAlign: "center" }}>{this.state.loader}</div>
                        <InputComponents.CheckBox className="facet-panel"
                            facets={facets}
                            facetsList={this.state.siteNameList}
                            onChange={this.changeSiteName}
                            onSearch={this.searchSiteName}
                            isDataTruncated={this.state.isSitesTruncated}
                            selectedList={this.state.selectedSiteName}
                        />
                    </Panel>

                </Collapse>
                <div className={this.state.isCollapsed ? "collapsed" : "toggled"} onClick={this.onExpandAndCollapse}>
                    {
                        this.state.isCollapsed ? (<Tooltip title="Collapse">
                            <LeftCircleOutlined />
                        </Tooltip>
                        ) : <Tooltip title="Expand"><RightCircleOutlined /></Tooltip>
                    }
                </div>
            </div>
        )
    }
}

export default Sidebar;
import React from "react";
import { Table } from "antd";

import Sites from "./Sites";

class Countries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            expandedKeys: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.expandedKeys.length === 0 && this.state.expandedKeys.length > 0) {
            this.setState({expandedKeys : []});
        }
    }

    render() {

        const row = this.props.row;

        const columns = [
            { title: 'Countries' }
        ];

        return <div className="countryTable">
            <Table
                className="components-table-country-nested"
                tableLayout="fixed"
                bordered="true"
                key={record => record.name}
                showHeader={false}
                columns={columns}
                rowExpandable={(record) => {
                    return true;
                }}
                expandedRowKeys={this.state.expandedKeys}
                onExpand={(expanded, record) => {
                    if (expanded) {
                        this.setState({ expandedKeys: [...this.state.expandedKeys, record] })
                    }
                    else {
                        let list = [...this.state.expandedKeys];
                        list.splice(list.indexOf(record), 1);
                        this.setState({ expandedKeys: list });
                    }
                }}
                expandable={{
                    expandedRowRender: (row) => {
                        return <Sites row={row} country={row} region={this.props.region} selectedDate={this.props.selectedDate} filterData={this.props.filterData} />
                    }
                }}
                dataSource={row}
                pagination={false}
                rowKey={(record) => {
                    return record;
                }}
            />
        </div>
    }
}

export default Countries;
import '../asset/css/footer.css';
import React from "react";

class Footer extends React.Component{
    render(){
        return(
            <div className="footer">
                &#169; All Rights Reserved
            </div>
        )
    }
}

export default Footer;
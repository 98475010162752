import '../asset/css/table.css'
import '../asset/css/index.css'
import React from 'react';
import { Table, Popover, Card, DatePicker, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import jsonDiff from 'json-diff';
import Countries from "../components/Countries";
import MonthScreenService from '../service/MonthScreen';
import Constant from '../util/Constant.json';
import { getCommonElements, getURL } from '../util/index';
import js from 'jsonpath';
const style = {
    margin: '10px'
}
class MonthScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            selectedDate: new Date().getFullYear() + '-' + ("0" + (new Date().getMonth() + 1)).slice(-2),
            regionList: [],
            filterData: [],
            selectedFilters: { status: true },
            shouldRender: true,
            loader: <Spin spin={true} size='large' />,
            expandedKeys: [],
            changeDate: false,
            siteNameFilterRegion: null
        };
        localStorage.setItem('monthData', null);

    }

    filterDataByAvailableData = async (date, filterData = {}) => {
        filterData.date = date;
        let saveData = localStorage.getItem('monthData');
        if (saveData === undefined || saveData === null) {
            localStorage.setItem('monthData', JSON.stringify(filterData));
        }
        else {
            let diff = jsonDiff.diff(JSON.parse(saveData), filterData);
            if (diff) {
                localStorage.setItem('monthData', JSON.stringify(filterData));
            }
            else {
                return false;
            }
        }
        let url = getURL(Constant.URL.GET_MONTHLY_DATA);
        if (date) {
            url.searchParams.append('date', date);
        }
        let availableData = await MonthScreenService.getMonthData(url.href, {});
        return availableData;
    }

    changeDate = (date, dateString) => {
        this.setState({ selectedDate: dateString, changeDate: true });
        // this.props.onMonthChange(dateString);
    }


    async componentDidMount() {

        let response = JSON.parse(localStorage.getItem('sideBarData'));

        let data = response.message.regions;
        let filterData = await this.filterDataByAvailableData(this.state.selectedDate);
        this.setState({ data: data, loader: '', filterData: filterData.message.regions || [], expandedKeys: [], changeDate: false });

    }

    setLoader() {
        this.setState({ loader: <Spin spin={true} size='large' /> });
    }

    async componentDidUpdate() {

        let data = await this.filterDataByAvailableData(this.state.selectedDate, this.props.filterData);
        let result = null;
        if (data) {
            let response = JSON.parse(localStorage.getItem('sideBarData'));
            if (this.props.filterData.siteName.length) {
                result = this.getCountryAndRegion(this.props.filterData.siteName, response);
            }
            if (this.state.changeDate) {
                this.setState({ filterData: data.message.regions || [], loader: '', changeDate: false, siteNameFilterRegion: result });
            }
            else {
                this.setState({ filterData: data.message.regions || [], loader: '', expandedKeys: [], siteNameFilterRegion: result });
            }
        }
    }

    getCountryAndRegion(selectedSiteName, response) {
        let countries = this.searchSiteName(selectedSiteName, response);
        let region = response.message.regions;
        let regionList = [];
        countries.forEach(country => {
            region.forEach(region => {
                if (region.countries.includes(country)) {
                    if (!regionList.includes(country))
                        regionList.push(region.name);
                }
            });
        });

        return { countries, regionList };
    }

    searchSiteName(selectedSiteName, response) {
        let countries = [];
        selectedSiteName.forEach(element => {
            for (const [key, value] of Object.entries(response.message)) {
                if (key !== 'regions') {
                    let search = [...js.query(value, `$.sites.*[?(@.SiteName == "${element}")]`)];
                    if (search.length) {
                        if (!countries.includes(key))
                            countries.push(key);
                    }
                }
            }
        });
        return countries;
    }

    disabledDate = (current) => {

        if (moment().year() < current.year()) {
            return true;
        }
        else if (moment().year() === current.year() && current.month() > moment().month()) {
            return true;
        }
        else {
            return false;
        }
    }

    render() {
        const columns = [
            { title: 'Region' }
        ];
        let data = this.state.data;
        let regionData = [];
        if (this.props.filterData.region && this.props.filterData.region.length)
            this.props.filterData.region.map(d => regionData.push(d));
        else if (this.props.filterData.country.length) {
            
            data.map(d => {
                this.props.filterData.country.forEach(c => {
                    if (d.countries.includes(c) && !regionData.includes(d.name))
                        regionData.push(d.name);
                });

            });
        }
        else if (this.props.filterData.siteName.length && this.state.siteNameFilterRegion)
            data.map(d => {
                if (this.state.siteNameFilterRegion.regionList.includes(d.name))
                    regionData.push(d.name);
            });
        else
            data.map(d => regionData.push(d.name));

        // let filterRegion = [];
        // this.state.filterData.map(d => {
        //     filterRegion.push(d.name);
        //     return false;
        // });
        let countries = [];
        this.state.filterData.map(d => {
            countries = [...countries, ...d.countries];
            return false;
        });

        // if (this.props.filterData.siteName.length && this.state.siteNameFilterRegion) {
        //     countries = countries.filter(d => this.state.siteNameFilterRegion.countries.includes(d));
        //     console.log(countries);
        // }
        return (
            <React.Fragment>
                <Card title={<div className="">
                    <div style={{ float: "left" }}>Stores</div>
                    <div style={{ float: "right" }}>
                        <DatePicker defaultValue={moment()}
                            disabledDate={this.disabledDate}
                            onChange={(date, dateString) => this.changeDate(date, dateString)} picker="month" />

                        <Popover
                            content={(
                                <>
                                    <p className="missingTile f16 mb1"><span style={style}>M | Missing</span></p>
                                    <p className="fileProcessingTile f16 mb1"><span style={style}>E | Error in Master</span></p>
                                    <p className="ledgerErrorTile f16 mb1"><span style={style}>EL | Error in Ledger</span></p>
                                    <p className="warehouseErrorTile f16 mb1"><span style={style}>EW | Error in Warehouse</span></p>
                                    <p className="warehouseLedgerErrorTile f16 mb1"><span style={style}>ELW | Error in Warehouse & Ledger</span></p>
                                    <p className="processingTile f16 mb1"><span style={style}>Y | Processed</span></p>
                                    <p className="inProgressTile f16 mb1"><span style={style}>IP | In Progress</span></p>
                                    <p className="closedTile f16 mb1"><span style={style}>C | Closed</span></p>
                                    <p className="warehouseErrorTile f16 mb1"><span style={style}>EWCN | Error in China</span></p>
                                    <p className="warehouseErrorTile f16 mb1"><span style={style}>EWAU | Error in China-APAC</span></p>
                                </>

                            )}
                            title={<h6>Status Code Sheet</h6>}
                            trigger="hover"
                        >
                            <span> <InfoCircleOutlined /></span>
                        </Popover>
                    </div>
                </div>
                }>
                    {
                        this.state.loader ? <div style={{ textAlign: "center" }}>{this.state.loader} </div> :
                            <Table
                                bordered="true"
                                className="regionTable"
                                columns={columns}
                                size={"small"}
                                showHeader={false}
                                pagination={false}
                                dataSource={regionData}
                                expandedRowKeys={this.state.expandedKeys}
                                onExpand={(expanded, record) => {
                                    if (expanded) {
                                        this.setState({ expandedKeys: [...this.state.expandedKeys, record] })
                                    }
                                    else {
                                        let list = [...this.state.expandedKeys];
                                        list.splice(list.indexOf(record), 1);
                                        this.setState({ expandedKeys: list });
                                    }
                                }}
                                rowKey={(record) => {
                                    return record;
                                }}
                                expandable={{
                                    expandedRowRender: (row) => {
                                        let region = row;
                                        data.map(d => {
                                            if (d.name === row) {
                                                if (this.props.filterData.country && this.props.filterData.country.length)
                                                    row = getCommonElements(this.props.filterData.country, d.countries);
                                                else if (this.props.filterData.siteName.length && this.state.siteNameFilterRegion)
                                                    row = this.state.siteNameFilterRegion.countries
                                                else
                                                    row = d.countries;
                                            }
                                            return false;
                                        });
                                        let regionCountruList = data.find(item=> item.name === region).countries;
                                        return <Countries expandedKeys={this.state.expandedKeys} row={row.sort().filter(country => regionCountruList.includes(country))} region={region} selectedDate={this.state.selectedDate} filterCountries={countries} filterData={this.props.filterData} />
                                    }
                                }}
                                rowExpandable={(record) => {
                                    return true;

                                }}
                            />
                    }

                </Card>
            </React.Fragment>
        );
    }
}
export default MonthScreen;
import React from "react";
import '../asset/css/topbar.css';
import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import logo from "../asset/images/travelex-logo-old.svg"
import middlewareLogo from "../asset/images/middleware-logo.png";
import { LogoutOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

class Topbar extends React.Component {

    onLogout = () => {
        Swal.fire({
            title: 'Logout',
            text: 'Do you want to logout?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                Cookies.set('code', '');
                window.location.href = `/login`;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // window.location.href = `/login`;
            }
        });
    }

    render() {
        return <Row>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="nav__bg">
                <Col lg={2}>
                    <Navbar.Brand href="#home">
                        <img
                            alt=""
                            src={logo}
                            width="160"
                            height="50"
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                </Col>
                <Col lg={4} >
                    <Navbar.Brand href="#home" className="project-name">RTS Middleware Dashboard</Navbar.Brand>
                </Col>
                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav>
                        <Navbar.Brand href="/dashboard" style={{ position: 'absolute', right: '2%', top: '1%' }}>
                            <img
                                alt=""
                                src={middlewareLogo}
                                width="45"
                                height="40"
                                className="d-inline-block align-top"
                                style={{ transform: 'rotate(-90deg)' }}
                            />


                        </Navbar.Brand>
                    </Nav>
                </Navbar.Collapse>
            </Navbar >
        </Row>
    }
}

export default Topbar;

// & nbsp;& nbsp;& nbsp;
// {
//     window.location.href.includes('dashboard') ? (<LogoutOutlined spin={false} onClick={this.onLogout} />) : ''
// }
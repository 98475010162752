import Swal from 'sweetalert2';
import { getRandomString, encryptStringWithSHA256, hashToBase64url } from "../util/index"
import Cookies from 'js-cookie';
import {sleep} from '../util/index'
const API_RETRY_DELAY = 1000;
class APICall {

    async request(URL, requestOptions = {}) {
        let code = Cookies.get('code');
        if (code) {
            return new Promise(async (resolve, reject) => {
                try {
                    // Setting headers
                    let headers = {
                        "code": Cookies.get('code'),
                        'code_verifier': Cookies.get('code_verifier'),
                        'applicationId': process.env.REACT_APP_APPLICATION_ID
                    };
                    if (requestOptions.headers) {
                        requestOptions.headers = { ...requestOptions.headers, ...headers }
                    }
                    else
                        requestOptions.headers = headers;

                    let retryCountLength = 3;
                    for (let retryCount = 0; retryCount <= retryCountLength; retryCount++) {
                        let response = await fetch(URL, requestOptions);
                        if (response.status === 200) {
                            resolve(await response.json());
                            break;
                        }
                       await sleep(API_RETRY_DELAY);
                    }
                    reject('Retry count exceed');
                }
                catch (e) {
                    throw e;
                }
            });
        }
        else {
            this.sweetAlert();
        }
    }

    sweetAlert() {
        Swal.fire({
            title: 'Session has expired',
            text: 'Redirecting to the login page',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = `/login`;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // window.location.href = `/login`;
            }
        });
    }

    getLoginURL = async () => {
        const state = getRandomString();
        // console.log(`state value is ${state}`);
        //localStorage.setItem("state", state);
        Cookies.set('state', state)
        // Create PKCE code verifier
        const code_verifier = getRandomString();
        // console.log(`code_verifier value is ${code_verifier}`);
        //localStorage.setItem("code_verifier", code_verifier);
        Cookies.set('code_verifier', code_verifier)
        // Create code challenge
        const arrayHash = await encryptStringWithSHA256(code_verifier);
        const code_challenge = hashToBase64url(arrayHash);
        // console.log(`code_challenge value is ${code_challenge}`);
        //localStorage.setItem("code_challenge", code_challenge)
        Cookies.set('code_challenge', code_challenge)
        // Redirtect user-agent to /authorize endpoint
        const LOGIN_URL = process.env.REACT_APP_DOMAIN_NAME + "/oauth2/authorize?response_type=code&state=" + state + "&client_id=" + process.env.REACT_APP_APP_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_REDIRECT_URI + "&scope=openid&code_challenge_method=S256&code_challenge=" + code_challenge;
        // console.log(`LOGIN_URL value is ${LOGIN_URL}`)

        return LOGIN_URL;
    }
}
export default new APICall();
import APICall from './api-call';

class SideBar {

    static async getRegionData(URL, requestOption) {
        try {
            let response = await APICall.request(URL, requestOption);
            return response;
        } catch (error) {
            console.log('Exception occurred while fetching region', error);
            return {
                message: {
                    regions: []
                }
            };
        }
    }
}
export default SideBar;
import SiteDal from '../dal/Sites';

// Used to handle both components Day and Month Sites

class Sites {

    static async getMonthData(URL, requestOption) {
        let response = await SiteDal.getMonthData(URL, requestOption);
        return response.message;
    }

    static async getDayData(URL, requestOption) {
        let response = await SiteDal.getDayData(URL, requestOption);
        return response.message;

    }
}
export default Sites;